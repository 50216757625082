<script setup>

import IndexLayout from "@/Layouts/IndexLayout.vue";
import Card from "@/Components/Card.vue";
import {Link} from "@inertiajs/vue3";
import {route} from "ziggy-js";
import {computed} from "vue";
import {trans} from "laravel-vue-i18n";

const props = defineProps({ status: Number })

const title = computed(() => {
    return {
        503: `503: ${trans('Service unavailable')}`,
        500: `500: ${trans('Internal server error')}`,
        404: `404: ${trans('Page not found')}`,
        403: `403: ${trans('Access denied')}`,
        400: `400: ${trans('Invalid request')}`
    }[props.status]
})

const description = computed(() => {
    return {
        503: trans('Sorry, we are conducting maintenance. Please come back later.'),
        500: trans('We are already aware of the problem and are working on it.'),
        404: trans('Page does not exist.'),
        403: trans('You do not have access to this page.'),
        400: trans('Your request cannot be processed.')
    }[props.status]
})

</script>

<template>
    <IndexLayout :title="title">
        <div class="flex justify-center items-center m-auto max-w-[48.75rem] w-full pt-2.5 px-2.5 lg:px-0">
            <Card class="flex flex-col gap-5 px-3 pt-3 pb-8 w-full lg:min-h-[31rem]">
                <div class="lg:px-2.5">
                    <div class="flex gap-3 bg-slate-100 p-2 lg:items-center">
                        <div class="mt-1 lg:mt-0 w-4 h-4 lg:w-[4.75rem] lg:h-[5.375rem] bg-red-500 shrink-0">
                        </div>
                        <div class="text-xl font-['Unbounded'] font-medium">
                            <p>{{ trans('Error') }} <span class="uppercase">{{ status }}.</span></p>
                            <p>{{ description }}</p>
                        </div>
                    </div>
                </div>
                <div class="mx-auto">
                    <img :src="`/assets/img/${status}.webp`" alt="" class="rounded-lg max-w-[200px] max-h-[200px]">
                </div>
                <Link :href="route('home')" class="text-sm text-blue-600 underline text-center">
                    {{ $t('To main page (To Home page)') }}
                </Link>
            </Card>
        </div>
    </IndexLayout>
</template>

<style scoped>

</style>
